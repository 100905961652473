@import "../../../config";

.validatePanelBg {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background: rgba($dark, .3);
    transition: position 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1s cubic-bezier(0.685, 0.0473, 0.346, 1);
    opacity: 0;
}

.validatePanelBg.active {
    left: 0;
    opacity: 1;
}

.validatePanelBox {
    box-shadow: -10px 0 10px rgba($dark, .25);
    position: fixed;
    top: 0;
    bottom: 0;
    right: -150%;
    width: 30%;
    background: $white;
    padding: 1em 1em;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    overflow: hidden;
    z-index: 9999;
    min-width: 30em;
}

@media (max-width: 991px) {
    .previewDock,
    .validatePanelBox {
        width: 30em;
        min-width: 300px;
        max-width: 90%;
    }
}

@media (max-width: 760px) {
    .previewDock,
    .validatePanelBox {
        padding: .5em 1em;
    }
}

.validatePanelBox.active {
    right: 0;
}

.validatePanelBox .closeBtn {
    border-radius: 2em;
    position: absolute;
    right: .5em;
    top: .5em;
}

.validatePanelHeader {
    height: 2.5em;
    color: $primary;
}

.validatePanelContent {
    padding: 2.5em 0;
    width: 100%;
    height: calc(100% - 4.5em);
    overflow-x: hidden;
    overflow-y: auto;
}

.sortItem .alert,
.infoPanelContent .alert,
.validatePanelContent .alert {
    padding: .15em .5em;
    margin-bottom: .5em;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sortItem .alert,
.infoPanelContent .alert {
    white-space: nowrap;
}

.sortItem .btnImg,
.BranchesListBoxContent .btnImg,
.validatePanelContent .btnImg {
    width: 20px;
}

.validatePanelContent .alert-link {
    position: absolute;
    right: .5em;
    top: 50%;
    transform: translate(0, -50%);
    display: none;
}

.validatePanelContent .lastEdit .alert-link {
    display: block;
}

.infoPanelContent {
    height: calc(100vh - 5em);
}

.BranchesListBox,
.WordCountBox {
    padding: 1em 0;
    height: calc(100vh - 5em);
}

.BranchesListBoxContent {
    margin: 1em 0;
    width: 100%;
    max-height: calc(100vh - 12rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.BranchesListBoxContent .row:last-child {
    margin-bottom: 4em;
}

.WordCountBox {
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 2px solid $primary;
}

.WordCountBox hr {
    border-top-color: $primary;
}

.WordCountBox h5 {
    padding-top: 1em;
    padding-bottom: .5em;
    color: $primary;
    border-bottom: 1px solid $primary;
}

.InfoOverflowAdmin {
    color: $red;
}

.ConnectionButton {
    padding: .2em .4em;
}

.ConnectionButtonImg {
    width: 1.5em;
}

.BranchesListFilterBox {
    border-bottom: 2px solid $primary;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

.BranchesListFilterLabel {
    margin-right: .5em;
    margin-top: .25em;
    font-weight: bold;
    color: $primary;
}
