/* New blue design */
$theme-primary-color: #000;
$theme-primary-background: #fff;
$theme-secondary-color: #fff;
$theme-secondary-background: #6C74B4;
$theme-primary-btn-color: #fff;
$theme-primary-btn-background: #6C74B4;
$theme-warning-btn-background: #ff6251;
$theme-primary-btn-background-hover: lighten($theme-primary-btn-background,5);
$theme-warning-btn-background-hover: lighten($theme-warning-btn-background,5);

$button-active-transform: translateY(2px) scale(1.01);

/* Legacy */
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #767676 !default;
$gray-700: #495057 !default;
$gray-800: #2C2C2C !default;
$gray-900: #1C1C1C !default;
$black: #000 !default;


$blue: #1C6EA4 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$pinkLight: #F68993 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: gold !default;
$yellowDark: #FFD7AA;
$green: rgb(77, 232, 195) !default;
$teal: rgb(121, 255, 88) !default;
$cyan: #17a2b8 !default;

$body-bg: $white;


$primary: #ff6251;
$secondary: #ffb5a5;
$secondary-mid-light: #fad5cd;
$secondary-high-light: #f8e8e5;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-300;
$dark: $gray-800;
$darkRed: #B43434;

$primary_alt: #F7A397;
$gradient_1: #ff9a8f;
$gradient_2: #ffb996;
$gradient_3: #ffd49b;

$gray-main: #383838;

$btn-border-radius: 1.5em;

$check_color: #ffc280;
$switch_true_color: #75f94d;
$switch_false_color: #fb5d45;
$indicator_bg: #F5698C
//$forevolve-green: #007f00;
//$btn-border-width: 2px;
//$pagination-border-width: 2px;
//$dropdown-border-width: 2px;
//
//$white: #fff;
//$gray-100: #f8f9fa;
//$gray-200: #FCFCFC;
//$gray-300: #767676;
//$gray-400: #383838;
//$gray-500: #adb5bd;
//$gray-600: #6c757d;
//$gray-700: #495057;
//$gray-800: #2C2C2C;
//$gray-900: #212529;
//$black: #000;
//
//$color-darker-percent-full: 20%;
//$color-darker-percent-half: $color-darker-percent-full / 2;
//$color-darker-percent-quarter: $color-darker-percent-half / 2;
//$color-darker-percent-none: 0;
//
//$blue: lighten(#91D1FF, $color-darker-percent-half);
//$indigo: darken(#6610f2, $color-darker-percent-none);
//$purple: darken(#9C60F2, $color-darker-percent-none);
//$pink: darken(#F68993, $color-darker-percent-none);
//$red: darken(#F23D4C, $color-darker-percent-none);
//$orange: darken(#F48B3B, $color-darker-percent-none);
//$yellow: lighten(#F2E75A, $color-darker-percent-half);
//$green: darken(#7EFCB5, $color-darker-percent-none);
//$teal: darken(#7EFCB5, $color-darker-percent-none);
//$cyan: lighten(#17a2b8, $color-darker-percent-none);
//
//$primary: #F7A397;
//$secondary: #FFD7AA;
//$success: $green;
//$info: $cyan;
//$warning: $orange;
//$danger: $red;
//$light: $gray-300;
//$dark: $gray-800;
//
//$body-bg: $gray-300;
//$body-color: #d3d3d3;
//
//$yiq-text-dark: $gray-900;
//$yiq-text-light: $gray-400;
//
//$link-color: darken($body-color, 15%);
//$link-hover-color: darken($link-color, 15%);
//
//$border-color: $dark;
//
//$card-border-color: rgba($white, .125);
//$card-cap-bg: rgba($white, .03);
//$card-color: $gray-200;
//$card-bg: $gray-800;
//
//$nav-tabs-border-color: $primary;
//$nav-tabs-link-hover-border-color: $gray-700 $gray-700 $nav-tabs-border-color;
//$nav-tabs-link-active-color: $gray-100;
//$nav-tabs-link-active-bg: $body-bg;
//$nav-tabs-link-active-border-color: $gray-700 $gray-700 $nav-tabs-link-active-bg;
//$nav-tabs-link-active-color: $dark;
//$nav-tabs-link-active-bg: $primary;
//$nav-tabs-link-active-border-color: $primary;
//$nav-tabs-border-width: 2px;
//
//$component-active-color: $black;
//$component-active-bg: $primary;
//
//$list-group-bg: rgba($body-bg, .05);
//$list-group-border-color: rgba($white, .125);
//
//$list-group-hover-bg: $gray-900;
//$list-group-active-color: $component-active-color;
//$list-group-active-bg: $component-active-bg;
//$list-group-active-border-color: $list-group-active-bg;
//
//$list-group-disabled-color: $gray-400;
//$list-group-disabled-bg: $list-group-bg;
//
//$list-group-action-color: $gray-300;
//$list-group-action-hover-color: $list-group-action-color;
//
//$list-group-action-active-color: $body-color;
//$list-group-action-active-bg: $gray-800;
//
//$input-bg: $gray-300;
//$input-disabled-bg: $gray-800;
//
//$input-color: $gray-200;
//$input-placeholder-color: $input-color;
//$input-border-color: $gray-500;
//$input-box-shadow: inset 0 1px 1px rgba($white, .075);
//$input-border-radius: 1em;
//
//$input-focus-bg: $body-bg;
//$input-focus-border-color: lighten($component-active-bg, 25%);
//$input-focus-color: $input-color;
//
//$input-group-addon-color: $input-color;
//$input-group-addon-bg: $gray-800;
//$input-group-addon-border-color: $input-border-color;
//
//$custom-select-color: $input-color;
//$custom-select-disabled-color: $gray-400;
//$custom-select-bg: $input-bg;
//$custom-select-disabled-bg: $gray-800;
//$custom-select-indicator-color: $input-color;
//$custom-select-box-shadow: inset 0 1px 2px rgba($white, .075);
//$custom-select-border-color: $input-border-color;
//
//$modal-content-bg: $body-bg;
//$modal-content-border-color: rgba($white, .2);
//$modal-header-border-color: $primary;
//$modal-footer-border-color: $primary;
//$modal-header-border-width: 3px;
//$modal-footer-border-width: 3px;
//
//$close-color: $white;
//$close-text-shadow: 0 1px 0 $black;
//
//$table-color: $body-color;
//$table-border-color: $border-color;
//$table-hover-color: $table-color;
//
//$table-dark-color: $gray-300;
//
//$jumbotron-bg: $gray-800;
//
//$hr-border-color: rgba($white, .1);
//
//$toast-background-color: rgba($black, .85);
//$toast-border-color: rgba(255, 255, 255, .1);
//$toast-box-shadow: 0 .25rem .75rem rgba($white, .1);
//
//$toast-header-color: $gray-400;
//$toast-header-background-color: rgba($black, .85);
//$toast-header-border-color: rgba(255, 255, 255, .05);
//
//$dropdown-color: $primary;
//$dropdown-bg: $gray-300;
//$dropdown-border-color: $primary;
//$dropdown-divider-bg: $primary;
//$dropdown-box-shadow: 0 .5rem 1rem rgba($white, .175);
//
//$dropdown-link-color: $primary;
//$dropdown-link-hover-color: lighten($secondary, 5%);
//$dropdown-link-hover-bg: $gray-500;
//
//$dropdown-link-active-color: $component-active-color;
//$dropdown-link-active-bg: $component-active-bg;
//
//$dropdown-link-disabled-color: $gray-400;
//
//$dropdown-header-color: $primary;
//
//$breadcrumb-bg: $gray-800;
//$breadcrumb-divider-color: $gray-400;
//$breadcrumb-active-color: $gray-400;
//
//$pagination-color: $link-color;
//$pagination-bg: $dark;
//$pagination-border-color: $component-active-bg;
//
//$pagination-hover-color: $link-hover-color;
//$pagination-hover-bg: $dark;
//$pagination-hover-border-color: $gray-700;
//
//$pagination-active-color: $component-active-color;
//$pagination-active-bg: $component-active-bg;
//$pagination-active-border-color: $pagination-active-bg;
//
//$pagination-disabled-color: $gray-500;
//$pagination-disabled-bg: $dark;
//$pagination-disabled-border-color: $component-active-bg;

