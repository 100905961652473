@use "../../../styles/colors" as *;
@import "../../../config";

.navbar.bg-light {
  background-color: $body-bg !important;
}

.pageHeader {
  z-index: 999;
  position: relative;
}

.pageHeader.pageHeader-home .navbar.bg-light {
  background: transparent !important;
}

.pageHeaderLoged {
  position: absolute;
  left: 0;
  right: 0;
  height: 66px;
  border-bottom: 2px solid #D8DCE7;
  box-shadow: 0 2px 4px rgba($dark, 0.1);
  z-index: 1;
  width: calc(100% - 260px); // width - .sidebar width
  margin-left: 260px;
  padding-left: 1.5em;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

  &.menuCollapsed {
    margin-left: 0;
    width: 100%
  }
}

.headerNavbar {
  justify-content: end;
  background: $white;
}

@media (max-width: 991px) {
  .pageHeaderLoged {
    width: calc(100% - 230px);
    left: auto;
    right: 0;

    .navbar-brand {
      max-width: calc(100% - 3.7em);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

  }
  .menuCollapsed {
    .pageHeaderLoged {
      width: 100%;
    }
  }
}


.btnMenuCollapsible {
  position: absolute;
  right: -1.5em;
  top: 1em;
  width: 25px;
  height: 25px;
  line-height: 1.3em;
  border-radius: 25px;
  color: $dark;
  background-color: #EEEEEE;
  box-shadow: 3px 3px 10px rgba($dark, 0.35);
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: none !important;
  z-index: 999;
}

.btnMenuCollapsible svg {
  width: 14px;
  height: 14px;
}

.headerCollapse {
  display: flex;
  justify-content: end;
  align-items: center;
}

.headerUserInfo {
  display: flex;
  align-items: center;
}

.headerUserInfoImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 0.25em;
}

.darkRed {
  background-color: #8b0000 !important;
}

.maintenanceMassage {
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: .9em;
  background-color: #8b0000;
}

@media (max-width: 991px) {
  .maintenanceMassage {
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0, 0);
    padding: 1.5em;
  }
}

.dropdown-menu .nav-item .dropdown-toggle.nav-link {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: $dark;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  position: relative;
}

.dropdown-menu .nav-item .dropdown-toggle.nav-link:after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-1rem, -50%);
}

.headerUserInfoName {
  margin: 0 0.5em;
}

.emailVerifiedAlert {
  position: fixed;
  z-index: 999;
  top: 1.5em;
  left: 50%;
  transform: translate(-50%, 0);
}

.boxNotifications {
  position: fixed;
  top: 3.5em;
  right: .9em;
}
