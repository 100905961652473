.group {
  font-size: 0.875rem;
  font-weight: 700;
  padding: 1.5rem 0.5rem 0.25rem 0.5rem ;
  border: none;
  cursor: default;
}

.expandable {
  cursor: pointer;
}
