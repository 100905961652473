.dropdownMenuToggle {
  font-weight: 900;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1;
  width: 1.25em;
  height: 1.25em;

  &:hover {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:after {
    display: none;
  }
}
