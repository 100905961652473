@use "src/styles/colors" as *;

.container {
  display: flex;
  align-items: center;
  font-weight: 600;

  &:hover {
    img {
      filter: invert(30%) sepia(60%) saturate(500%) hue-rotate(320deg) brightness(95%) contrast(90%);
    }
    color: $link-primary-color;
  }
}

.iconContainer {
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.group {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 1.5rem 0.5rem 0.25rem 0.5rem ;
  border: none;
}
