@import "src/config";
.list {
  padding: 10px;
  min-height: 400px;
}

.audioRow {
 margin-bottom: 5px;
 background-color: pink;
}

.audioField {
  width: 100%;
  border-bottom: 1px solid $gray-400;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.audioSettings {
  display: flex;
}

.loopField {
  width: 80px;
  margin: 0 10px;
  padding-top: 7px;
}

.stepActionsField {
  width: 200px;
  margin: 0 10px;
  padding-top: 7px;
}

.volumeField {
  margin: 0 10px;
  height: 40px;
}

.volumeLabel {
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
  transform: translate(0, -15px);
}

.playButton {
  min-width: 50px;
  max-height: 40px;
  margin-left: 15px;
}

.audioPickerButton {
  width: 100%;
}

.removeButton {
  margin: 0 10px;
}


.videoRow {
  margin-bottom: 5px;
 }

.videoField {
   width: 100%;
   border-bottom: 1px solid $gray-400;
   height: calc(1.5em + 0.5rem + 2px);
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 0.2rem;
}

.videoSettings {
   display: flex;
}

.videoPickerButton {
   width: 100%;
}
