$color-primary: black;

$btn-primary-color: white;
$btn-primary-bg: #6d72b2;

$btn-primary-hover-color: $btn-primary-color;
$btn-primary-hover-bg: #5c61a0;

$btn-primary-active-color: $btn-primary-color;
$btn-primary-active-bg: #4b508f;

$btn-primary-focus-color: $btn-primary-color;
$btn-primary-focus-bg: #5c61a0;

$btn-menu-color: black;
$btn-menu-bg: white;
$btn-menu-hover-bg: #f1f1f1;
$btn-menu-active-bg: #f1f1f1;
$btn-menu-focus-bg: #f1f1f1;

$bg-color-primary: #ffffff;
$bg-color-secondary: #f1f1f1;
$bg-color-page-content: #EEF2F6;
$br-color-accent: #dde8f3;

$link-primary-color: #D44682;
$link-primary-hover-color: #2C1961 ;
$link-primary-focus-color: #2C1961;
$link-primary-active-color: #2C1961;
