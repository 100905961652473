.dialogClassName {
    //max-width: 50%;
    height: auto;
  }

.contentClassName {
  height: 100%;
}

.volumeField {
  margin: 0 10px;
  max-width: 200px;
}

.volumeLabel {
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
  transform: translate(0, -15px);
}