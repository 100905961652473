.StoriesPage {
    /*
    display: flex;
    width: 100%;
    */
}

.arrow-asc {
    display: inline-block;
    top: 14px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid gray;
}

.arrow-desc {
    display: inline-block;
    top: 14px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid gray;
}

.btnImg {
    width: 1.8em;
}

svg.btnImg {
    width: 1.8em;
    height: 1.8em;
}

.btnImg.btnImgSm {
    width: 14px;
}

.btnDeleteImgSm {
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/delete.svg");
    background-position: center;
    background-size: 16px;
    width: 2em;
    height: 2em;
}

th.arrow {
    position: relative;
    padding-right: 11px;
    cursor: pointer;
}

th.arrow:after, th.arrow:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #cccccc transparent;
    display: block;
    width: 0;
    right: .75rem;
    top: 50%;
    transform: translate(0, -50%);
}

th.arrow:after {
    border-width: 10px 5px 0;
    margin-top: 6px;
}

th.arrow:before {
    border-width: 0 5px 10px;
    margin-top: -6px;
}

th.arrow[data-sort=asc]:before, th.arrow[data-sort=desc]:after {
    border-color: black transparent;
}

.zIndexModal {
    z-index: 99999 !important;
    background-color: rgba(0, 0, 0, .1);
}

.size-35 {
    width: 35%;
}

.tooltip-box {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    line-height: 1;
    opacity: 1 !important;
}

.tooltip-box:hover {
    overflow: visible;
}

.tooltip-box .tooltip {
    position: absolute;
    display: block !important;
    opacity: 0;
    bottom: 90%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    min-width: 7em;
    text-align: center;
}

.tooltip-box:hover .tooltip {
    position: absolute;
    opacity: 1;
    bottom: 100%;
}

.tooltip-box .tooltip-inner {
    margin-bottom: .4rem;
}

.tooltip-box .arrow {
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    display: block !important;
    opacity: 1;

}

.tooltip-box:hover .arrow {
    position: absolute;
    bottom: 0;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    opacity: 1;
    background: transparent;
}

.tooltip-box .arrow:before {
    transform: translate(-50%, 0);
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000;
}

.tooltip-box .arrow-left {
    bottom: 10%;
    left: calc(100% - 0.6em);
    transform: translate(-100%, 0);
    position: absolute;
    display: block !important;
    opacity: 1;
}
.tooltip-box .tooltip-left {
    left: 100%;
    transform: translate(-100%, 0);
}

.story-move {
    cursor: move;
}

.move-item {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 10em;
    border: 3px solid rgba(255, 171, 153, 0.3);
    border-radius: 10px;
    background-color: rgba(16, 246, 46, 0.3);
}

.modalRole {
    //z-index: 9999;
    background: rgba(0, 0, 0, .5);
}

.collapseContent {
    overflow: hidden;
    max-height: 100%;
}

.collapseWrapper {
    margin-top: -500%;
    overflow: hidden;
    transition: all .85s ease-in-out;
}

.sectionCollapsible {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 2rem;
}

.collapseLabel {
    display: block;
    cursor: pointer;
    position: relative;
    margin-bottom: 1em;
    margin-top: 1em;
}

.collapseLabel .card-title {
    display: inline-block;
    padding-right: 2em;
    position: relative;
}

.collapseLabel .card-title:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    padding: .3em;
    border-left: 2px solid #F7A397;
    border-top: 2px solid #F7A397;
    transform: translate(50%, 0) rotate(-135deg) translate(100%, 0);
    transform-origin: 50%;
    transition: all .75s ease-in-out;
}

.collapseInput:checked ~ .collapseLabel .card-title:before {
    transform: translate(-50%, 0) rotate(45deg);
}

.collapseInput {
    display: none;
}

.collapseInput:checked ~ .collapseContent .collapseWrapper {
    margin-top: 0;
}
