@import "../../../config";

.boxBranchList {
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.react-draggable {
  width: 200px;
}

.react-draggable, .cursor {
  cursor: move;
}

.no-cursor {
  cursor: auto;
}

.cursor-y {
  cursor: ns-resize;
}

.cursor-x {
  cursor: ew-resize;
}

.main-panel > .content.content-branches {
  padding: 0;
  width: 100%;
  height: 100%;
}

.gridBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);

  &.gridBgShow {
    background-image:
            linear-gradient(90deg, rgba(255, 181, 165, 0.5) 3px, transparent 0),
            linear-gradient(180deg, rgba(255, 181, 165, 0.5) 3px, transparent 0),
            linear-gradient(90deg, rgba(0, 0, 0, 0.09) 1px, transparent 0),
            linear-gradient(180deg, rgba(0, 0, 0, 0.065) 1px, transparent 0);
    background-size: 200px 200px, 355px 350px, 25px 25px, 25px 25px;
  }
}

.content-branches-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.content-branches-wrapper {
  position: relative;
  transform-origin: 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.4s;
}

.branchesDragItem,
.content-branches-wrapper .handle {
  width: 200px;
  height: 290px;
  touch-action: none;
}

.branchesDecsionPoint,
.branchesDragItem.handle_branchesDecsionPoint,
.content-branches-wrapper .handle.handle_branchesDecsionPoint {
  height: 340px !important;
}

.content-branches-wrapper .handle > div {
  height: 100%;
}

.branchesScale_25 .wrapItems-selection > div:first-child {
  transform: scale(4);
  z-index: 999;
}

.branchesScale_50 .wrapItems-selection > div:first-child {
  transform: scale(2);
  z-index: 999;
}

.branchesScale_75 .wrapItems-selection > div:first-child {
  transform: scale(1.35);
  z-index: 999;
}

.handleLastEdit,
.handle > .lastEdit {
  z-index: 99 !important;
}

.branchEdit {
  z-index: 999 !important;
}

.branchesItems {
  width: 180px;
  height: calc(100% - 20px);
  margin: 10px auto !important;
}

.branchesDecsionPoint {
  border-radius: 30px;
  min-width: 200px;
}

.branchesDecsionPoint .card-footer {
  border-radius: 0 0 30px 30px;
}

.no-overflow {
  overflow: hidden;
}

.cardBranchContent {
  width: 100%;
  height: calc(100% - 2.4em);
  overflow: hidden;
  padding: 0 2px;

  &.showAnalytic {
    background-color: #253439;
    border-radius: 10px 10px 0 0;
    font-size: 1.2em;
    padding: .3em 0;
    display: flex;
    justify-content: center;
  }

  .locationImg {
    overflow: hidden;
    width: 100%;
    height: 5.5em;
    background: no-repeat center;
    background-size: cover;
  }

  .description {
    height: 4.5em;

    &.descriptionLocationImg {
      height: auto;
    }
  }

}

.branchesDecsionPoint .cardBranchContent {
  height: calc(100% - 3.6em);
  display: grid;
}

.traitImgContainer {
  width: 100%;
  padding: .3em 0;
  overflow: hidden;
  height: 4em;
  align-self: flex-end;
}

.dropOffContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5px;
  line-height: 1;
  color: #FFFFFF;
}

.dropOff {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  border: 3px solid white;
  margin: 6px auto;
  position: relative;

  .dropOff-span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    width: auto;
    white-space: nowrap;
  }
}

.dropOffBad {
  box-shadow: inset 0 0 0 5px $primary;
  color: $primary;
}

.traitImg {
  height: 3.75em;
  width: 3.75em;
  border-radius: 3.75em;
  border: 2px solid $secondary;
  background-color: #FFFFFF;
  margin: -.2em 0 0 0;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.traitImg img {
  height: 2.8em;
  width: 2.8em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.csImg {
  height: 2em;
  width: 2em;
}

.choiceStatistics {
  padding-top: 10px;
  color: white;
  background-color: #253439;
  border-radius: 10px 10px 0 0;
  height: calc(100% - 2.2em);
}

.csContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.csLabel {
  width: 102.4px;
  height: 15px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-left: 1.5em;
}

.csProgressBar {
  height: 20px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  float: left;
  background-color: rgba(150, 10, 10, 50);
  border: 2px solid black;
  border-radius: 10%;
  margin-left: 3px;
  margin-right: 3px;
}

.csProgressBarFiller {
  height: 16px;
  padding: 2px;
  background-color: red;
  max-width: 50px;
}

.csValue {
  float: right;
  align-self: flex-end;
  text-align: left;
  margin-right: 0;
  margin-left: 10px;
  overflow: visible !important;
}

.selecting .branchesItems,
.selected .branchesItems {
  border-color: $success;
  box-shadow: 0 0 40px 0 $success;
  background-color: lighten($success,30);
}

.lastEdit {
  border-color: $secondary;
  box-shadow: 0 0 40px 10px $secondary;
  background-color: lighten($secondary,30);
}

.branchesDragItem {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  cursor: move;
}

.opacity {
  opacity: .7;
}

.wrapLines {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.wrapItems {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.branchesItems .card-body {
  margin: 0;
  padding: .2em;
  font-size: 14px;
  height: 100px;
  overflow: hidden;
}

.branchesItemsLong .card-body {
  height: 100px;
}

.branchesDecsionPoint .card-body {
  border-radius: 30px 30px 0 0;
}

.branchesItems .h5 {
  font-size: 18px;
}

.branchesItems .card-body p {
  line-height: 1.2em;
  margin-bottom: 1em;
  min-height: 2.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.branchesItems .card-title input {
  font-size: 1.1em;
  line-height: 1em;
  margin: 0;
  padding: 0;
  font-weight: bold;
  border-color: #fff;
  background-color: #ffffff;
  text-align: center;
}

.branchesItems .card-title span {
  font-size: 1.1em;
  line-height: 1em;
  font-weight: bold;
  display: block;
  padding: .47em .3em;
  margin-bottom: .1em;
}

.branchesItemsLong .card-title span {
  max-height: 1.5em;
  overflow: hidden;
}

.branchesItems .card-footer {
  padding: .2em 0;
  height: 40px;
  background-color: gray;
}

.branch_choice .card-footer {
  background-color: #80ccff;
}

.branch_intro .card-footer {
  background-color: #8fbc8f;
}

.branch_gold .card-footer {
  background-color: #FFCC00 !important;
}
.branch_check .card-footer {
  background-color: $check_color !important;
}

.line_dashed {
  position: absolute;
  left: 0;
  top: -5px;
  border-top: 2px dashed lime;
}

.line_dashed:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  background: transparent;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: block;
  padding: 0;
  margin-top: -1px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 15px solid lime;
}

.line_solid {
  position: absolute;
  left: 0;
  top: -5px;
  border-top: 2px solid fuchsia;
}

.line_solid:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  background: transparent;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: block;
  padding: 0;
  margin-top: -1px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 15px solid fuchsia;
}

.line_switch_true {
  border-top: 2px dashed $switch_true_color;
}
.line_switch_true:before {
  border-top: 15px solid $switch_true_color;
}

.line_switch_false {
  border-top: 2px dashed $switch_false_color;
}
.line_switch_false:before {
  border-top: 15px solid $switch_false_color;
}

.check_card_info {
  width: 100;
}
.switch_info {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.switch_true {
  color: $switch_true_color;
}
.switch_false {
  color: $switch_false_color;
}

.branchesScale_25 .line_solid,
.branchesScale_25 .line_dashed {
  border-top-width: 4px;
}

.branchesScale_50 .line_solid,
.branchesScale_50 .line_dashed {
  border-top-width: 3px;
}

.branchesScale_75 .line_solid,
.branchesScale_75 .line_dashed {
  border-top-width: 2px;
}

.content-dbClickArea {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &.cursor-point {
    cursor: url(../../../assets/images/flag.png), pointer;
    z-index: 99999;
  }
}

.duplicateBranchsAlert {
  position: fixed;
  bottom: 1.7em;
  right: 6em;
  z-index: 9;

  img {
    margin-right: 1em;
  }
}

.branchesList {
  display: flex;
}

.modeAlert {
  position: fixed;
  top: 4.3em;
  right: 1.2em;
  font-size: .9em;
  z-index: 99;
  width: 16em;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel.preview-panel .modeAlert {
  right: 34.4em;
}

.CustomSelect {

  .dropdown-menu {
    .show {
      z-index: 99999;
      min-width: 300px;
    }
  }

  .list-unstyled {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 225px;
    display: flex;
    flex-direction: column;
  }

  .btn-outline-secondary {
    color: $gray-800;
    border-color: $gray-400 !important;
    border-radius: 0.25rem;
  }

  .dropdown-item {
    &.active, &:active {
      color: $gray-800;
      background-color: rgba($gray-400, .4);
    }

    &:hover,
    &[active="true"].active,
    &[active="true"]:active {
      background-color: $secondary;
    }
  }

  .dropdown-item {
    min-height: 2em;
    order: 0;

    .intro-last {
      order: 100;
    }

    .disabled {
      display: none !important;
    }
  }

  .arr-right {
    padding-right: 1.6em;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      right: .6em;
      top: 50%;
      border: 2px solid #212529;
      border-top: 0;
      border-left: 0;
      border-radius: 2px;
      width: .6em;
      height: .6em;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 3;
    }
  }
}

.preview {
  position: absolute;
  right: 0;
  top: 55px;
  height: 900px;
  width: 530px;
}

.def_location_option {
  color: grey;
}

.selectable-selectbox {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
  background: rgba(16, 238, 112, .2);
}

.SelectableGroupDisabled .selectable-selectbox {
  display: none;
}

.content-branches {
  scroll-behavior: smooth;

  &.content-branches-shift {
    scroll-behavior: auto;
  }
}

.was-validated .form-control.text-limit:valid,
.form-control.is-valid.text-limit,
.text-limit,
.text-limit:focus {
  border-color: $red !important;
  background: #FFB6C1 !important;
}

.char-limit-info-box {
  position: absolute;
  right: 17px;
  top: 1px;
  font-size: .6em;
  padding: .2em .5em;
  background: #fff;
  margin: 0;
  z-index: 100;
  white-space: nowrap;
}

.handle .char-limit-info-box {
  right: 5px;
  top: 3px;
}

.modal-body .char-limit-info-box {
  right: 6px;
  top: auto;
  bottom: 19px;
}

.numInpBtn {
  border-color: #ced4da;
}

.an-box {
  display: block;
  font-size: .9em;
  line-height: 1em;
  text-align: left;
  padding: .1em .5em;
}

.editNameBg {
  position: fixed;
  background: rgba(255, 255, 255, .1);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.editNameInp {
  position: relative;
  z-index: 100;
}

.addBranchNumberBox {
  position: relative;
}

.addBranchNumberBox .form-control {
  border-width: 2px;
  border-color: $primary;
}

.menuCollapsed .addBranchNumberBox {
  display: none;
}

.addBranchNumberBox .input-group-append {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  height: 100%;
  width: 1em;
  border-left: 1px solid $primary;
}

.addBranchNumberBox .input-group-append .btn {
  border-radius: 0 !important;
}

.addBranchNumberBox .numInpBtn {
  font-size: .7em;
  padding: .15em .3em;
  position: absolute;
  right: 0;
  top: 0;
  max-height: 50%;
  border: 0 !important;
}

.addBranchNumberBox .numInpBtn:first-child {
  top: 50%;
}

.savePositionLoading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}

.story-branches-spinner {
  position: absolute;
  bottom: 35px;
  right: 80px;
}


.DeselectAll-button {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#bolInitConnection {
  position: absolute;
  width: 1em;
  height: 1em;
  background: #f0f;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 1em;
}

#lineConnection {
  transform: translate(-50%, -50%);
  background: #f0f;
  height: 2px;
  z-index: 99;
}

#bolInitConnection.choice, #lineConnection.choice {
  background: #0f0;
}

#lineConnection:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 100%;
  background: transparent;
  -webkit-transform: translate(-1em, -50%) rotate(-90deg);
  transform: translate(-1em, -50%) rotate(-90deg);
  display: block;
  padding: 0;
  margin-top: -1px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 15px solid #f0f;
}

#lineConnection.choice:before {
  border-top: 15px solid #0f0;
}

.hoverConnection {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: transparent;
  display: none;
}

.activeConnection .handle:hover .branchesItems {
  border-color: #f0f;
  box-shadow: 0 0 5px 0 #f0f;
}

.activeConnectionChoice .handle:hover .branchesItems {
  border-color: #0f0;
  box-shadow: 0 0 15px 0 #0f0;
}
