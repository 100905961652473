.videoPlayerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.customControls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.customControls:hover {
  opacity: 1;
}

.controlButton {
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: white;
  font-size: 1rem;
  margin: 0 4px;
  padding: 6px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.exitFullscreenButton {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px;
  font-size: 1.2rem;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

.volumeControl {
  position: relative;
  display: flex;
  align-items: center;
}
