.container {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  margin: 1em 0;
  font-weight: 600;
}

.button {
  padding-left: 0;
  animation: fadeIn 0.5s ease-in forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
