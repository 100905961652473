@import "./config";
@import "~bootstrap/scss/bootstrap";

@import "./styles/index";


.modal-title {
  color: $primary;
}



.btn-brand1 {
  background-color: $primary;
  color: $dark;
  border-width: 0.2rem;
  position: relative;
}

.btn-brand1:hover {
  background-color: $secondary;
  color: $dark;
}

.boxBadge {
  position: absolute;
  right: 0;
  top: -.25em;
  text-align: right;
  line-height: 1;
}

.btn-brand1 .badge {
  font-size: .5em;
  top: 0;
  margin: 0 1px;
  padding: .5em;
  font-weight: 600;
}

.btn-outline-brand1 {
  background-color: white;
  color: $primary;
  border-color: $primary;
  border-width: 0.2rem;
}

.btn-outline-brand1:focus {
  background-color: white;
  color: $primary;
  border-color: $primary;
  border-width: 0.2rem;
}

.btn-link {
  color: $primary;
  font-weight: bold;
}

a {
  color: $primary;
  font-weight: bold;
}

.btn-link:hover {
  color: $secondary;
}

.badge-indicator {
  color: $white;
  background-color: $indicator_bg;
}

a:hover {
  color: $secondary;
}

/**/
.tagItem {
  border: 1px solid $primary;
}

.tagItem .close {
  background: $primary !important;
  color: $dark !important;
}

.card-body {
  box-shadow: 0 0 0 1px rgba($dark, 0.12), 0 4px 16px rgba($dark, 0.25);
}

.circleBox {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 15px;
  border: 2px solid rgba($primary, 0.4);
}

.circleBox.checked {
  background: rgba($primary, 0.4);
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .show > .nav-link,
.dropdown-menu .nav-item .dropdown-toggle.nav-link {
  color: $darkRed !important;
}

.navbar-dark {
  .navbar-toggler {
    position: absolute;
    right: .5em;
    top: .25em
  }
}

.navbar-light .navbar-brand.site-logo,
.site-logo {
  height: 66px; // the same as .pageHeaderLoged
  font-size: 2em;
  font-weight: bolder;
  display: flex;
  align-items: center;
}

.site-logo .img-container {
    display: flex;
    justify-content: left;
    padding-left: 1.5rem;
}

.site-logo .img-container {
    display: flex;
    justify-content: left;
    padding-left: 1.5rem;
    padding-top: 0.5rem;
}

.site-logo img {
  height: 48px;
  object-fit: contain;
}

@media (max-width: 860px) {
  .site-logo img {
    min-width: 1.2em;
  }
}

.btn-secondary {
  color: $darkRed !important;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-outline-dark,
.btn-outline-dark {
  background-color: $white;
  border-color: $darkRed !important;
  color: $darkRed !important;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-purple,
.btn-purple {
  color: $white;
  background-color: $purple;
  border-color: $purple;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-goldBorder,
.emulatorScreen .btn.passiveChoiceBtn.btn-yellow,
.btn-goldBorder,
.btn-yellow {
  color: $white;
  background-color: $yellow;
  border-color: $yellow;
}

.emulatorScreen .btn.passiveChoiceBtn.btn-goldBorder {
}

.btn-group .dropdown-menu {
  overflow-y: auto;
  max-height: 80vh
}

.react-select-container {

  &.sm > .react-select__control {
    min-height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .react-select__control {
    border: 1px solid $gray-400;

    &.react-select__control--is-focused {
        border-color: $secondary-mid-light;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(255, 98, 81, 0.25);
    }

    .react-select__indicators {
      .react-select__indicator {
        &.react-select__clear-indicator {
          padding: 0;
        }
        &.react-select__dropdown-indicator {
          padding: 3px;
        }
      }
    }
  }

  .react-select__multi-value {
    background: $light;
    .react-select__multi-value__remove {
      padding: 0;
    }
  }

  .react-select__menu {
    border: 1px solid $primary;
    z-index: 5; // To overlap the invalid-tooltip class
    .react-select__menu-list.react-select__menu-list--is-multi {
      .react-select__option--is-selected {
        color: unset;
        background: $light;
      }
      .react-select__option--is-focused {
        color: unset;
        background: $secondary;
      }
    }
  }

  .react-select__option {
    padding: 0 0.5rem;
  }

  .react-select__option {
    &.react-select__option--is-selected {
      background: $primary;
      color: $body-bg;
    }
    &.react-select__option--is-focused {
      background: $secondary;
      color: $dark;
    }
  }


}



.react-select__value-container.react-select__value-container--is-multi {
  padding: 0;
}



