@import "colors";

.btn-menu-v2 {
  background-color: $btn-menu-bg;
  color: $btn-menu-color;
  padding: 0 0.5rem;

    &:hover {
      color: $link-primary-color;
    }

    &:focus {
      color: $link-primary-color;
      box-shadow: none;
      outline: none;
    }

    &:active {
      color: $link-primary-color;
      box-shadow: none;
      outline: none;
    }
}


.btn-asLinkV2 {
  background-color: transparent;
  color: $link-primary-color;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  font-weight: bold;
  scale: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $link-primary-hover-color;
    transform: scale(1.01);
  }

  &:focus {
    color: $link-primary-focus-color;
    box-shadow: none;
    outline: none;
  }

  &:active {
    color: $link-primary-active-color;
    box-shadow: none;
    outline: none;
  }
}


.btn-none {
  background-color: inherit;
  color: lighten($btn-menu-color, 40%);
  padding: 0;
  margin: 0;
  font-weight: bold;

  &:hover {
    color: $btn-menu-color;
  }

  &:focus {
    background-color: inherit;
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: inherit;
    box-shadow: none;
    outline: none;
  }
}

.btn-primary-v2 {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;

    &:hover {
        color: $btn-primary-hover-color;
        background-color: $btn-primary-hover-bg;
    }

    &:focus {
        color: $btn-primary-focus-color;
        background-color: $btn-primary-focus-bg;
        box-shadow: none;
        outline: none;
    }

    &:active {
        color: $btn-primary-active-color;
        background-color: $btn-primary-active-bg;
        box-shadow: none;
        outline: none;
    }
}
