@import "src/config";

.label {
  position: absolute;
  top: -1.125rem;
  font-size: 0.75rem;
  left: 0.25rem;
}

.formGroup {
  position: relative;
}
