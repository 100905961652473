.container {
  padding-top: 0.25em;
  margin-bottom: 1em;
}

.title {
  padding: 0;
  margin: 0;
}

.subtitle {
  font-weight: 500;
}

.link {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
