.wrapper {
  position: relative;
  background: transparent url("../../../assets/images/Pixel-2.svg");
  background-size: cover;
}

.previewContainer {
  transform: translate(13%, 8%);
  overflow: hidden; // extra code to make cure CT doesn't overflow preview area
}

.previewContainerDetached {
  transform: translate(0, 0);
  margin: auto;
  padding: 0;
  overflow: hidden; // extra code to make cure CT doesn't overflow preview area
}

:global {
  button {
    // piece of reset.css in order for CT to work
    background: transparent;
    border: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
    bottom: 0;
  z-index: 999;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
