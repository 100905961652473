@import "src/config";

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
}

.fieldTitle {
 padding: 0 10px ;
}

.fieldAlias {
  padding: 0 15px ;
  font-style: italic;
  font-size: small;
  color: $gray-500;
}

.fieldProcessingStatus,
.fieldProcessingStatusError {
  padding: 0 0 0 15px;
  font-style: italic;
  font-weight: bold;
}
.fieldProcessingStatus {
  color: $blue;
}
.fieldProcessingStatusError {
  color: $red;
}

.fieldActions {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.selected {
  background-color: lighten($secondary,13);
  border-radius: 5px;
}

.grayedout {
  opacity: 0.5;
  cursor: default;
}
