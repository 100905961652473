.thumbnail {
  overflow: hidden;
  width: 100%;
  height: 5.5em;
  background: no-repeat center;
  background-size: cover;
  position: relative;
}

.videoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0.6;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;
    opacity: 0.8;
  }
}
