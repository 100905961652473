@import "src/config";

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.fieldTitle {
 padding: 0 10px ;
}

.fieldAlias {
  padding: 0 15px ;
  font-style: italic;
  font-size: small;
  color: $gray-500;
}

.fieldDuration,
.fieldType,
.fieldOwner {
  text-align: center;
}

.fieldActions {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.buttonPlay {
  width: 35%;
}

